import React, { FC, useMemo } from 'react';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import { useWidgetViewModel } from '../hooks/useWidgetViewModel';
import { ServiceCard } from './ServiceCard/ServiceCard';
import {
  DataHooks,
  DEFAULT_IMAGE_CONTAINER,
  SERVICE_INFO_LABEL_ID,
  SIDE_PADDING,
} from './consts';
import { VerticalAlignmentProvider } from '../hooks/useVerticalAlignment';
import { classes, st } from './Body.st.css';
import {
  generateServiceInfoDetails,
  getDetailsAlignment,
} from '../../../../utils/serviceDetails/displayOptions';
import { useServiceInfoLayout } from '../hooks/useServiceInfoLayout';
import { Grid } from '../Grid';
import { useVisibilityCheck } from '../../hooks/useVisibilityCheck';
import { LoadServicesButton } from './LoadServicesButton/LoadServicesButton';
import { useWidgetActions } from '../hooks/useWidgetActions';
import { useLayoutPerBpInWidget } from '../../hooks/useLayoutPbpInWidget';
import { SectionGrid } from './SectionGrid/SectionGrid';

export interface BodyProps {
  widgetId: string;
}

export const Body: FC<BodyProps> = ({ widgetId }) => {
  const {
    services,
    bodyViewModel,
    businessInfo,
    coursesAvailability,
    servicesPagingMetadata,
    groupedServicesByCategories,
  } = useWidgetViewModel();
  const serviceInfoLayout = useServiceInfoLayout();
  const { t } = useTranslation();
  const { language, isMobile, dimensions } = useEnvironment();
  const { layoutStylePerBpEnable } = useLayoutPerBpInWidget();
  const { shouldBeVisible } = useVisibilityCheck();
  const isServiceDividerVisible = shouldBeVisible('isServiceDividerVisible');
  const isTagLineVisible = shouldBeVisible('isTagLineVisible');
  const { loadServicesByPage } = useWidgetActions();
  const isServiceOfferedDaysVisible = shouldBeVisible(
    'isServiceOfferedDaysVisible',
  );
  const isServiceStartDateVisible = shouldBeVisible(
    'isServiceStartDateVisible',
  );
  const isServiceDurationVisible = shouldBeVisible('isServiceDurationVisible');
  const isServicePriceVisible = shouldBeVisible('isServicePriceVisible');
  const detailsAlignment = useMemo(() => {
    const allServiceInfoDetails = services.map((service) =>
      generateServiceInfoDetails({
        service,
        displayOptions: {
          isServiceDurationVisible,
          isServiceOfferedDaysVisible,
          isServicePriceVisible,
          isServiceStartDateVisible,
          isTagLineVisible,
        },
        t,
        language,
        businessInfo,
        coursesAvailability,
      }),
    );
    return getDetailsAlignment({
      allServiceInfoDetails,
      serviceInfoLayout,
      isServiceDividerVisible,
    });
  }, [
    services,
    serviceInfoLayout,
    isServiceDividerVisible,
    isServiceDurationVisible,
    isServicePriceVisible,
    isServiceStartDateVisible,
    isServiceOfferedDaysVisible,
    isTagLineVisible,
  ]);

  const { width, height } = DEFAULT_IMAGE_CONTAINER;
  const {
    spaceBetweenCards,
    maxCardsPerRow,
    cardMinWidth,
    cardMaxWidth,
    showDivider,
    dividerWidth,
    imageRatio,
    loadMoreButtonText,
    loadPreviousButtonText,
    focusCardIndex,
    loadMoreSeoHref,
    loadPreviousSeoHref,
  } = bodyViewModel;

  return layoutStylePerBpEnable ? (
    <div
      className={st(classes.root, {
        layoutStylePerBpEnable,
      })}
      data-hook={DataHooks.ROOT}
      style={
        {
          '--image-aspect-ratio': imageRatio,
        } as React.CSSProperties
      }
    >
      <VerticalAlignmentProvider value={detailsAlignment}>
        {loadPreviousButtonText && (
          <LoadServicesButton
            href={loadPreviousSeoHref}
            className={classes.loadPreviousButton}
            text={loadPreviousButtonText}
            dataHook={DataHooks.LOAD_PREVIOUS}
            onClick={() =>
              loadServicesByPage(servicesPagingMetadata.minPageLoaded - 1)
            }
          />
        )}
        {groupedServicesByCategories ? (
          groupedServicesByCategories.map(
            ({ category, services: categorizedServices }) => (
              <SectionGrid
                key={category.id}
                dataHook={`${DataHooks.SECTION_GRID}-${category.id}`}
                title={category?.name!}
                services={categorizedServices}
              />
            ),
          )
        ) : (
          <SectionGrid dataHook={DataHooks.SECTION_GRID} services={services} />
        )}
        {loadMoreButtonText && (
          <LoadServicesButton
            href={loadMoreSeoHref}
            className={classes.loadMoreButton}
            text={loadMoreButtonText}
            dataHook={DataHooks.LOAD_MORE}
            onClick={() =>
              loadServicesByPage(servicesPagingMetadata.maxPageLoaded + 1)
            }
          />
        )}
      </VerticalAlignmentProvider>
    </div>
  ) : (
    <div
      className={classes.root}
      data-hook={DataHooks.ROOT}
      style={
        {
          '--image-aspect-ratio': imageRatio,
        } as React.CSSProperties
      }
    >
      <VerticalAlignmentProvider value={detailsAlignment}>
        {loadPreviousButtonText && (
          <LoadServicesButton
            href={loadPreviousSeoHref}
            className={classes.loadPreviousButton}
            text={loadPreviousButtonText}
            dataHook={DataHooks.LOAD_PREVIOUS}
            onClick={() =>
              loadServicesByPage(servicesPagingMetadata.minPageLoaded - 1)
            }
          />
        )}
        <Grid
          id={`grid-${widgetId}`}
          className={classes.grid}
          width={dimensions.width ? dimensions.width - SIDE_PADDING * 2 : 0}
          rowGap={spaceBetweenCards}
          columnGap={spaceBetweenCards}
          maxColumns={maxCardsPerRow}
          minColumnWidth={cardMinWidth}
          maxColumnWidth={cardMaxWidth}
          showRowDivider={showDivider}
          dividerWidth={dividerWidth}
          uniformRowHeight={!isMobile}
          isMobile={isMobile}
        >
          <span style={{ display: 'none' }} id={SERVICE_INFO_LABEL_ID}>
            {t('offering.info.aria-title')}
          </span>
          {services.map((service, index) => (
            <Grid.Item
              key={`${service.id}-${index}`}
              focused={index === focusCardIndex}
            >
              <ServiceCard
                service={service}
                width={width}
                height={height}
                index={index}
              />
            </Grid.Item>
          ))}
        </Grid>
        {loadMoreButtonText && (
          <LoadServicesButton
            href={loadMoreSeoHref}
            className={classes.loadMoreButton}
            text={loadMoreButtonText}
            dataHook={DataHooks.LOAD_MORE}
            onClick={() =>
              loadServicesByPage(servicesPagingMetadata.maxPageLoaded + 1)
            }
          />
        )}
      </VerticalAlignmentProvider>
    </div>
  );
};
