import React, { FC } from 'react';
import { st, classes } from './NewGrid.st.css';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import { Text, TextTypography as TYPOGRAPHY } from 'wix-ui-tpa/cssVars';
import { useSettings } from '@wix/tpa-settings/react';
import settingsParams from '../../settingsParams';
import { DataHooks } from '../Body/consts';

interface NewGridProps {
  title?: string;
  dataHook: string;
  numberOfServices: number;
}
export const NewGrid: FC<NewGridProps> = ({
  title,
  dataHook,
  children,
  numberOfServices,
}) => {
  const { isMobile } = useEnvironment();
  const settings = useSettings();

  return (
    <div className={st(classes.gridWrapper, { isMobile })} data-hook={dataHook}>
      {title && (
        <Text
          className={classes.menuTitle}
          data-hook={DataHooks.MENU_TITLE}
          typography={TYPOGRAPHY.smallTitle}
          tagName={settings.get(settingsParams.widgetMenuTitleFontHtmlTag)}
        >
          {title}
        </Text>
      )}
      <ul
        data-hook="Grid-container"
        className={st(classes.gridContainer, {
          uniformRowHeight: !isMobile,
          numberOfServices,
        })}
      >
        {children}
      </ul>
    </div>
  );
};
